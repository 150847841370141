var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5d46efa4563b90793023ffd4538cf203592b6a53"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { ExtraErrorData } from '@sentry/integrations'

Sentry.init({
  dsn: 'https://e35a5a8a637e4316940bb4d3d4c1c1e5@o984320.ingest.sentry.io/5940155',
  tracesSampleRate: 0.001,
  environment: process.env.NEXT_PUBLIC_APP_ENVIRONMENT,
  normalizeDepth: 10,
  integrations: [new ExtraErrorData({ depth: 10 })],
  beforeBreadcrumb(breadcrumb) {
    switch (breadcrumb.category) {
      case 'console':
        return null
      default:
        return breadcrumb
    }
  },
  // logLevel: 2,
  ignoreErrors: [
    "Cannot read property 'DOMNodeInsertedByJs' of undefined",
    'UET is not defined',
    'document.getElementsByClassName.ToString',
    // some defaults from https://docs.sentry.io/platforms/javascript/configuration/filtering/
    'top.GLOBALS',
    'originalCreateNotification',
    'canvas.contentDocument',
    'fb_xd_fragment',
  ],
  // @ts-ignore
  denyUrls: [
    /vitals\.vercel-analytics\.com/i,
    //optimonster
    /a\.omappapi\.com/i,
    //frase
    /app\.frase\.io/i,
    // some defaults from https://docs.sentry.io/platforms/javascript/configuration/filtering/
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /chrome-extension:\//i,
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  beforeSend: (event, hint) => {
    if (process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'local') {
      console.error(`Sentry Error:`, hint?.originalException || hint?.syntheticException)
      return null
    }
    return event
  },
})
